import { map, sumBy, find, orderBy, filter, uniq, capitalize, findIndex, sortBy} from 'lodash'
import alpha from 'color-alpha';
import vendorsArray from '../../components/widgets/filter/enums/vendors';
import moment from 'moment';
import countriesByAbbreviation from '../../components/common/CountriesByAbbreviation';
import rand from '../../helpers/rand';
import {percentageValue, splitByThousands} from '../../helpers/NumberFormatter';
import {dateRange, formattedDateRange, dateRangeFlat} from '../../helpers/DateRange';
import { colors } from '../../components/widgets/charts/ChartDefaults'; 
import { formatChartLabelItem, sortDateRange } from '../../helpers/DateFormatter';
import FixNumericFields from '../helpers/FixNumericFields';

export const statsFormatter = {
    formatTop,
    formatCatalogStats,
    formatVendor,
    formatTopTerritory,
    formatTopTerritoryTimeseries,
    formatTerritory,
    formatTerritoryTimeseries,
    formatDemographics,
    formatDemographicsTimeseries,
    formatDevices,
    formatSources,
    formatSourcesTimeseries,
    formatTransactions,
    formatTransactionsTimeseries,
    formatStatementTotals
};

const vendorTypesToIncludeInTitle = ['Video', 'Radio'];

function formatCatalogStats(data){
        let settings = {
            imprints: {icon: "user", route: '/catalog/imprints'},
            artists: {icon: "users", route: '/catalog/artists'},
            products: {icon: "trophy", route: '/catalog/products'},
            tracks: {icon: "music", route: '/catalog/tracks'}
        },
        result = [];
        data = data [0];
        for (let key of Object.keys(settings)){
            result.push({
                "entity": key,
                "icon": settings[key].icon,
                "title": `Total ${capitalize(key)}`,
                "absoluteValue": data[`curr_${key}`],
                "percentageValue": null, //percentageValue(data[`curr_${key}`], data[`prev_${key}`]),
                "route": settings[key].route
            })
        }
        return result;
}

function _getVendorLabel(item) {
    let label = item.vendor; 
    if(vendorTypesToIncludeInTitle.includes(item.content_type) && item.vendor.indexOf(item.content_type)===-1)
        label = `${item.vendor} (${item.content_type})`;
    return label;
}

function formatTop(data){
    let icons = {
        "Stream": "music",
        "Video": "play",
        "Download": "cloud-download-alt",
        "Radio": "broadcast-tower",
        "Audiobook": "book",
        "Social Music": "share-square"
    },
    result = [];
    for (let item of data){
        let key = item.content_type;
        result.push({
            "entity": key,
            "icon": icons[key],
            "title": key,
            "absoluteValue": item.curr_units,
            "percentageValue": percentageValue(item.curr_units, item.prev_units),
            "route": '',
            vendors: item.vendor_totals,
            curr_units: item.curr_units,
            prev_units: item.prev_units
        })
    }
    return sortBy(result, (result) => Object.values(icons).indexOf(result.icon));;
}

function formatVendor(mainData, mode, vendor, contentType, totals){
    const typeOrder = ['Stream', 'Video', 'Radio', 'Download', 'Audiobook', 'Social Music'];
    const { total_income } = totals;
    
    let vendors,
        labels,
        datasets;
    
    if(!mainData)
        return {};

    let data = [];
    
        
    //
    for(let item of data) {
        item.stms_by_date = item.totals;
        item.stms_by_date = item.stms_by_date.sort(sortDateRange);
    }    
    //
    
    data = sortBy(data, (item) => typeOrder.indexOf(item.content_type));
    mainData = sortBy(mainData, (item) => typeOrder.indexOf(item.content_type));
    
    //const origData = Object.assign([], data);
    const totalStreams = sumBy(mainData, 'curr_units');
    for(let item of mainData) {
        const source = item.src.toLowerCase(); //Number(item.physical_income) === 0 ? 'digital' : 'physical';
        item.source = (source == 'nr') ? 'Neighboring Rights' : capitalize(source);
        item.income = Number(item[`${source}_income`]);
        item.quantity = Number(item[`${source}_quantity`]);
        item.share = Math.round(item.income/total_income*10000)/100;
    }
    
    if(contentType==null)
        contentType = data.length ? data[0].content_type : '';
    else
        contentType = contentType.value;
        
    
//    for(let colorIndex in mainData) {
//        let item = data[colorIndex];
//        const itemVendor = find(vendorsArray, (vendor)=>vendor.label == item.vendor);
//        item.color = itemVendor ? itemVendor.hex[item.content_type] : colors[colorIndex];
//        item.total = sumBy(item.stms_by_date, 'curr_units');
//        item.growth = item.stms_by_date.length ? (item.stms_by_date[item.stms_by_date.length-1].curr_units - item.stms_by_date[0].curr_units) : 0;
//    }
    
    if(contentType)
        data = filter(data, (item)=>item.content_type==contentType);
    
    vendors=data.map(item=>item.vendor);
    
    if(vendor!=='all'){
        data = filter(data, (item)=>item.vendor==vendor);
    }    
    
    const chartData = mainData.slice(0, 20);
    
    //switch(mode){
        /*
        case 'stacked_bar': 
        case 'timeline':
            const { dates, period } = dateRange(data);
            datasets = data.map(vendor=>{
                let data = [];
                for(let date of dates) {
                    let stms = find(vendor.stms_by_date, {stream_date: date});
                    data.push(stms ? stms.curr_units : null);
                }    
                return {
                    label: _getVendorLabel(vendor),
                    data: data, 
                    borderColor: vendor.color,
                    backgroundColor: vendor.color,
                    pointBorderColor: vendor.color,
                    pointBackgroundColor: vendor.color,
                    fill: false
                }});    

            labels =  map(dates, (date)=>formatChartLabelItem(date, period));
        break;
        */
      //  case 'pie':
            //const total = sumBy(data, 'curr_units');
            /*, backgroundColor: mainData.map(vendor=>vendor.color */
            datasets = [{ data : chartData.map(vendor=>vendor.total_income), label: ' income' }];
            labels = chartData.map(vendor=>`${vendor.customer}`); // (${Math.round(vendor.curr_units/total*100)}%)`);
        //break;    
    //}
    
    
    mainData = FixNumericFields(mainData);
    return {chart:{labels, datasets, vendors}, table: mainData, data};
}


function formatTopTerritory(data, totals){
    if(!data)
        return {};
    const { total_income } = totals;        
    let chart = {},
        world = {},
        timeline = {},
        table;
    
    //const total = sumBy(data, 'curr_units');
    
    table = filter(map(data, (territory=>{
//        const value = sumBy(territory.data, (item)=>item[1]),
//            country = find(countriesByAbbreviation, {abbreviation: territory.name});
//        if(!country)
//            return null;
        
        const code = territory.country_code,
            country = find(countriesByAbbreviation, {abbreviation: code});
        
        if(territory.country_code == 'ZZ')
            territory.territory_name = 'Unknown';
        
        territory.name = country ? country.country : '-';
        territory.code = code;
        territory.value = territory.curr_units;
        territory.share = Math.round(territory.total_income/total_income*10000)/100;
        territory.perCapita = territory.per_cap_units;
        return territory;
    })));
    table = orderBy(table, ['value'], ['desc']);
    
    for (let rank = 0; rank < table.length; rank++) {
        let item = table[rank];
        item.rank = rank + 1; 
    }
    
    const chartData = table.slice(0, 20);
    
    for(let territory of chartData){
        world[territory.code] = territory.value;
    }                
//    switch(mode){
//        case 'pie':
            let mainDataset = {data: [], labels: [], backgroundColor: []},
                sourceDataset = {data: [], labels: [], backgroundColor: []},
                labels = [];
            for(let territoryIndex in chartData) {
                const color = colors[territoryIndex%colors.length],
                    territory = chartData[territoryIndex];
                
                labels.push(territory.name);
                labels.push(territory.name + ' digital');
                labels.push(territory.name + ' nr');
                labels.push(territory.name + ' physical');
                mainDataset.data.push(Number(territory.total_income));
                mainDataset.data.push(0);
                mainDataset.data.push(0);
                mainDataset.data.push(0);
                mainDataset.backgroundColor.push(color);
                mainDataset.backgroundColor.push(color);
                mainDataset.backgroundColor.push(color);
                mainDataset.backgroundColor.push(color);
                sourceDataset.data.push(0);
                sourceDataset.data.push(Number(territory.digital_income));
                sourceDataset.data.push(Number(territory.nr_income));
                sourceDataset.data.push(Number(territory.physical_income));
                
                sourceDataset.backgroundColor.push(color);
                sourceDataset.backgroundColor.push(alpha(color, 0.9));
                sourceDataset.backgroundColor.push(alpha(color, 0.7));
                sourceDataset.backgroundColor.push(alpha(color, 0.5));
            }
            chart = {
                datasets: [sourceDataset, mainDataset], labels
            }
//        break;
        /*
        case 'line':
            const { dates, period } = dateRange(data);
            timeline.datasets = table.map(territory=>{
                
                territory.stms_by_date = territory.stms_by_date.sort(sortDateRange);
                let data = [];
                for(let date of dates) {
                    let stms = find(territory.stms_by_date, {stream_date: date});
                    data.push(stms ? stms.units : null);
                }    
                
                return {
                    label: territory.name,
                    data,
                    fill: false
                };
            });
            timeline.labels =  map(dates, (date)=>formatChartLabelItem(date, period));
            
        break;
        */        
//    }
        
    table = FixNumericFields(table);    
    return {chart, world, table, timeline};
}

function formatTopTerritoryTimeseries(streams) {
    let labels = [],
        datasets = [];
    if(streams) {
        const {dates, period} = dateRangeFlat(streams);
        const territories = uniq(map(streams, 'country_code'));
        datasets = territories.map((territory, index)=>{
            const territoryData = find(countriesByAbbreviation, {abbreviation: territory});
            let data = [];
            for(let date of dates) {
                let stream = find(streams, {report_date: date, country_code: territory});
                data.push(stream ? Number(stream.total_income).toFixed(2) : null);
            }    
    
            return {
                id: territory,
                label: territoryData ? territoryData.country : 'Unknown',
                data,
                fill: false,
                borderColor: colors[index%colors.length],
                backgroundColor: colors[index%colors.length],
                pointBorderColor: colors[index%colors.length],
                pointBackgroundColor: colors[index%colors.length],                
    
            }
        });    
        
        labels =  map(dates, (date)=>formatChartLabelItem(date, 'Month'));
    }
    return { labels, datasets };

}

function formatTerritory(data, limit = 10){
    
    if(!data)
        return {};
        
    let chart = {},
        world = {},
        table,
        total = 0;
    
    table = filter(map(data, (territory=>{

        console.log(territory.country_code);
        if(territory.country_code == 'ZZ')
            territory.territory_name = 'Unknown';
        else
            territory.territory_name = find(countriesByAbbreviation, {abbreviation: territory.country_code});
        
        total += territory.curr_units;
        
        territory.name = territory.territory_name;
        territory.code = territory.country_code;
        territory.value = territory.curr_units;
        territory.total_units = territory.total_units;
        return territory;
    })));
    
    table = orderBy(table, ['value'], ['desc']);
    
    for (let rank = 0; rank < table.length; rank++) {
        let item = table[rank];
        item["rank"] = rank+1;
        // item["share"] = Math.round((item["value"]/item["total_units"])*100);
    }
    
    for(let territory of table.slice(0, limit)){
        world[territory.code] = territory.value;
    };
    /*
     * 
     pie

    chart = {
        labels: table.slice(0, limit).map(territory=>territory.name),
        datasets: [{ data : table.map(territory=>territory.value), label: 'plays'}]
    }
    */
    
    let labels = [],
    datasets = [];
    const { dates, period } = dateRange(data);
    datasets = data.map(territory=>{
        territory.stms_by_date = territory.stms_by_date.sort(sortDateRange);
        let data = [];
        for(let date of dates) {
            let stms = find(territory.stms_by_date, {stream_date: date});
            data.push(stms ? stms.units : null);
        }    
            
        return {
            label: territory.name,
            data,
            fill: false
        }
    });
    
    labels =  map(dates, (date)=>formatChartLabelItem(date, period));
    chart = { labels, datasets };
            
    return {chart, world, table};
}

function formatTerritoryTimeseries(data, mode, limit = 10){
    if(!data)
        return {};
        
    let chart = {},
        world = {},
        timeline = {},
        table;
    
    const total = sumBy(data, 'curr_units');
    
    table = filter(map(data, (territory=>{
//        const value = sumBy(territory.data, (item)=>item[1]),
//            country = find(countriesByAbbreviation, {abbreviation: territory.name});
//        if(!country)
//            return null;
        
        const code = territory.territory,
            country = find(countriesByAbbreviation, {abbreviation: code});
        
        territory.name = country ? country.country : '-';
        territory.code = code;
        territory.value = territory.curr_units;
        territory.share = Math.round(territory.curr_units/territory.total_units*100);
        territory.perCapita = territory.per_cap_units;
        
        return territory;
    })));
    table = orderBy(table, ['value'], ['desc']);
    for(let territory of table.slice(0, limit)){
        world[territory.code] = territory.value;
    }                
    for(let territory of data) {
        territory.stms_by_date = territory.totals;
    }
    
    const { dates, period } = dateRange(data);
    
    timeline.datasets = table.map(territory=>{
        
        territory.stms_by_date = territory.stms_by_date.sort(sortDateRange);
        let data = [];
        for(let date of dates) {
            let stms = find(territory.stms_by_date, {stream_date: date});
            data.push(stms ? stms.curr_units : null);
        }    
        
        return {
            label: territory.name,
            data,
            fill: false
        };
    });
    timeline.labels =  map(dates, (date)=>formatChartLabelItem(date, period));
        
    
    return timeline;
}


function formatDemographics(data) {
    const genderOrder = ['Male', 'Female', 'Unknown'];
    
    let genders = uniq(map(data, 'gender')),
        ages = uniq(map(data, 'age')),
        datasets = {},
        active = {},
        passive = {},
        timelineLabels = [],
        timelineDatasets = [],        
        totalStreams = sumBy(data, 'curr_units');
    
    ages.sort();
    genders = sortBy(genders, (gender) => genderOrder.indexOf(gender));

    for(let gender of genders) {
        datasets[`${gender}_active`] = [];
        datasets[`${gender}_passive`] = [];
        active[gender] = [];
        passive[gender] = [];
    }
    
    for(let entry of data) {
        //datasets[entry.gender][ages.indexOf(entry.age)] = entry.curr_units;
        active[entry.gender][ages.indexOf(entry.age)] = entry.active;
        passive[entry.gender][ages.indexOf(entry.age)] = entry.passive;
        datasets[`${entry.gender}_active`][ages.indexOf(entry.age)] = entry.active; 
        datasets[`${entry.gender}_passive`][ages.indexOf(entry.age)] = entry.passive;
        entry.share = Math.round(entry.curr_units / totalStreams * 100);
    }
    
    datasets = map(datasets, (data, label)=>{
        const [gender, mode] = label.split('_');
        const color = colors[genderOrder.indexOf(gender)];
        const barColor = mode == 'active' ? alpha(color, 0.5): color;
        return {
            data, 
            label: `${gender} (${mode})`, 
            stack: gender, 
            active: active[gender], 
            passive: passive[gender],
            backgroundColor: barColor
        };
    });
    
    /*
    const {dates, period} = dateRange(data);
    timelineDatasets = data.map(entry=>{
        let data = [];
        for(let date of dates) {
            let stms = find(entry.stms_by_date, {stream_date: date});
            data.push(stms ? stms.units : null);
        }    
            
        return {
            label: `${entry.gender} ${entry.age}`,
            data,
            fill: false
        }
    });
    
    
    timelineLabels =  map(dates, (date)=>formatChartLabelItem(date, period));
    */
    timelineLabels = timelineDatasets = [];

    
    return {chart: {labels: ages, datasets}, table: data, timeline: {labels: timelineLabels, datasets: timelineDatasets}};
}

function formatDemographicsTimeseries(data) {
    const genderOrder = ['Male', 'Female', 'Unknown'];
    
    let demos = uniq(map(data, 'demographic')),
        datasets = {},
        timelineLabels = [],
        timelineDatasets = [],        
        totalStreams = sumBy(data, 'curr_units');
    
    demos.sort();

    for(let demo of demos) {
        datasets[demo] = [];
    }
        
    const {dates, period} = dateRangeFlat(data);
    datasets = demos.map((demo, index)=>{
        let days = [];
        for(let date of dates) {
            let stream = find(data, {stream_date: date, demographic: demo});
            days.push(stream ? stream.curr_units : null);
        }    
            
        return {
            label: demo,
            data: days,
            fill: false
        }
    });
    
    
    timelineLabels =  map(dates, (date)=>formatChartLabelItem(date, period));
    
    return {labels: timelineLabels, datasets: datasets};
}


function formatDevices(data) {
    let labels = [],
        dataset = [],
        total = sumBy(data, 'curr_units');
    
    for(let entry of data) {
        entry.share = Math.round((entry.curr_units/total)*100);
        
        labels.push(entry.device);
        dataset.push(entry.curr_units);
    }
    return {chart: {labels, datasets: [{ data:dataset, label: "Plays"}]}, table: data};    
}

function formatSources(data) {
    let labels = [],
        dataset = [],
        timelineLabels = [],
        timelineDatasets = [],
        total = 0;
    
    for(let entry of data) {
        labels.push(entry.source);
        dataset.push(entry.curr_units);
        total += entry.curr_units;
    }
    

    data = data.map(entry=>{
        //const days = entry.stms_by_date;
        entry.share = Math.round((entry.curr_units/total)*10000) / 100;
        //entry.growth = percentageValue(days[days.length - 1].units, days[0].units);
        return entry;
    })
    
    /*    
    const {dates, period} = dateRange(data);
    
    timelineDatasets = data.map(source=>{
        source.stms_by_date = source.stms_by_date.sort(sortDateRange);
        let data = [];
        for(let date of dates) {
            let stms = find(source.stms_by_date, {stream_date: date});
            data.push(stms ? stms.units : null);
        }    

        return {
            label: source.source,
            data,
            fill: false
            }
    });    
    
    timelineLabels =  map(dates, (date)=>formatChartLabelItem(date, period));
    */
    
    timelineLabels = timelineDatasets = [];

    return {chart: {labels, datasets: [{ data:dataset, label: "Plays"}]}, table: data, timeline: {labels: timelineLabels, datasets: timelineDatasets}};    
}

function formatSourcesTimeseries(data) {
    let labels = [],
        dataset = [],
        timelineLabels = [],
        timelineDatasets = [],
        total = 0;
    
    for(let entry of data) {
        labels.push(entry.source);
        dataset.push(entry.curr_units);
        total += entry.curr_units;
        entry.stms_by_date = entry.totals;
    }
    
    data = data.map(entry=>{
        const days = entry.totals;
        entry.share = Math.round((entry.curr_units/total)*100);
        entry.growth = percentageValue(days[days.length - 1].units, days[0].units);
        return entry;
    })
    
    
    const {dates, period} = dateRange(data);
    
    timelineDatasets = data.map(source=>{
        source.stms_by_date = source.stms_by_date.sort(sortDateRange);
        let data = [];
        for(let date of dates) {
            let stms = find(source.stms_by_date, {stream_date: date});
            data.push(stms ? stms.curr_units : null);
        }    

        return {
            label: source.source,
            data,
            fill: false
            }
    });    
    
    timelineLabels =  map(dates, (date)=>formatChartLabelItem(date, period));

    return {labels: timelineLabels, datasets: timelineDatasets};    
}

function formatTransactions(data){
	let totalIncome = sumBy(data, 'total_income'),
		totalQuantity = sumBy(data, 'total_quantity');
    for(let item of data) {
//        for(let key of Object.keys(item)) {
//            if(item[key].match(/^[0-9\.]+$/))
//                item[key] = Number(item[key]);
//        }
//        const source = Number(item.physical_income) === 0 ? 'digital' : 'physical';
//        item.source = source;
        item.share = Math.round(item.total_income/totalIncome*10000)/100;
        
    }
    data.unshift({
    	transaction_type: 'Total',
    	classification: 'Total',
    	source: 'Total',
    	total_income: totalIncome.toFixed(2),
    	total_quantity: totalQuantity
    })
    return data;
}

function formatStatementTotals(data){
    const statementsOrder = ['Physical', 'Digital', 'Nr', 'Total'];
    
    let periods = uniq(map(data, 'period')).sort().slice(-4);
    
    
    let months = {};
    let flatTable = [];
    for(let period of periods){
        let statements = data.filter(row=>row.period == period);
        let totals = {gross_income: sumBy(statements, 'gross_income'), charges: sumBy(statements, 'charges'), net_income:sumBy(statements, 'net_income'), period, source: 'Total'};
        statements.push(totals);
        months[period] = sortBy(statements, (statement) => statementsOrder.indexOf(statement.source));
    }
    let headerRow = [''];
    let captionRow = ['']
    for(let month of Object.keys(months)) {
        headerRow = headerRow.concat(['', month, '']);
        captionRow = captionRow.concat('Gross', 'Charges', 'Net');
    }
    flatTable.push(headerRow);
    flatTable.push(captionRow);
    for(let source of statementsOrder) {
        let row = [source];
        for(let month of Object.keys(months)) {
            let monthData = months[month];
            let statement = find(monthData, row=>row.source == source);
            if(!statement){
                statement = {gross_income: 0, charges: 0, net_income: 0, period: month, source};
            } 
            row = row.concat([splitByThousands(statement.gross_income, '$'), splitByThousands(statement.charges, '$'), splitByThousands(statement.net_income, '$')])
        }
        flatTable.push(row);
    }
    return flatTable;
}

function formatTransactionsTimeseries(data) {
    let sources = uniq(map(data, 'source')),
        datasets = {},
        timelineLabels = [],
        timelineDatasets = [],        
        totalStreams = sumBy(data, 'total_income');
    
    sources.sort();

    for(let source of sources) {
        datasets[source] = [];
    }
        
    const {dates, period} = dateRangeFlat(data);
    datasets = sources.map((source, index)=>{
        let days = [];
        for(let date of dates) {
            let stream = find(data, {report_date: date, source});
            days.push(stream ? stream.total_income : null);
        }    
            
        return {
            label: source,
            data: days,
            fill: false
        }
    });
    
    
    timelineLabels =  map(dates, (date)=>formatChartLabelItem(date, period));
    
    return {labels: timelineLabels, datasets: datasets};

}