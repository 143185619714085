import React from 'react';
import { connect } from 'react-redux';
import { find, isEqual, map } from 'lodash';
import store from '../../../helpers/store';

import ReactTable from "react-table";
import config from '../../../config/config';
import ReactSelect from '../../common/ReactSelect';
import CSV from '../../../helpers/CSVExport';
import cellHeaderHOC from '../Datagrid/CellHeaderHOC';
import { splitByThousands } from '../../../helpers/NumberFormatter';
import Box from '../Box';

import TerritoryStatsMap from './Territory/Map';
import LineChart from '../../widgets/charts/types/LineChart';
import DoughnutChart from '../../widgets/charts/types/DoughnutChart';
import ConfirmClick from '../ConfirmClick';
import { statsActions } from '../../../data/actions/stats';
import { territorySettings } from './Territory/Settings'; 

var worldSvg = require('!svg-inline-loader!../../../../public/img/world.svg');
var lineSvg = require('!svg-inline-loader!../../../../public/img/line.svg');
var tableSvg = require('!svg-inline-loader!../../../../public/img/table.svg');
var downloadSvg = require('!svg-inline-loader!../../../../public/img/download.svg');
import VendorLogo from "../VendorLogo";
import { dspLogos } from "../../common/Stats/utils";
const CellHeaderTable = cellHeaderHOC(ReactTable);


class TerritoryStats extends React.Component {
    
    options = [
       {label: 'Top Total Income', field: 'total_income', dir: 'DESC'},
       {label: 'Top Total Quantity', field: 'total_quantity', dir: 'DESC'},
       {label: 'Top Physical Income', field: 'physical_income', dir: 'DESC'},
       {label: 'Top Physical Quantity', field: 'physical_quantity', dir: 'DESC'},
       {label: 'Top Digital Income', field: 'digital_income', dir: 'DESC'},
       {label: 'Top Digital Quantity', field: 'digital_quantity', dir: 'DESC'},
       {label: 'Top NR Income', field: 'nr_income', dir: 'DESC'},
       {label: 'Top NR Quantity', field: 'nr_quantity', dir: 'DESC'},       
       {label: 'Bottom Total Income', field: 'total_income', dir: 'ASC'},
       {label: 'Bottom Total Quantity', field: 'total_quantity', dir: 'ASC'},
       {label: 'Bottom Physical Income', field: 'physical_income', dir: 'ASC'},
       {label: 'Bottom Physical Quantity', field: 'physical_quantity', dir: 'ASC'},
       {label: 'Bottom Digital Income', field: 'digital_income', dir: 'ASC'},
       {label: 'Bottom Digital Quantity', field: 'digital_quantity', dir: 'ASC'},
       {label: 'Bottom NR Income', field: 'nr_income', dir: 'ASC'},
       {label: 'Bottom NR Quantity', field: 'nr_quantity', dir: 'ASC'},
   ];

    limits = [10, 25, 50];
    
    constructor(props) {
        super(props);
        
        const territoryLimit = store.get('territoryLimit') || this.limits[0];
        
        this.state = {
          mode: "table",
          sort: this.options[0],
          limit: territoryLimit,
          logoDataFiltered: [],
          page: 0
        };
        this.setChartMode = this.setChartMode.bind(this);
        this.setSort = this.setSort.bind(this);
        this.exportToCsv = this.exportToCsv.bind(this);
        this.setLimit = this.setLimit.bind(this);
        this.setPage = this.setPage.bind(this);
        this.getTimeseries = this.getTimeseries.bind(this);
        this.tableScrollTop = 0;
    };    
    
    setChartMode(mode){
        this.setState({mode});
        if(mode == 'line')
            this.getTimeseries();
    }

    renderToolbar(){
        let toolbar = [];
        let modes = {
            'table': 'table',
            'line': 'chart-line'
        };

        
        const limitOptions = this.limits.map(limit => ({
            label: limit == 500 ? 'All' : limit,
            value: limit        
        })),
        limitValue = find(limitOptions, {value: this.state.limit});        
        
        toolbar.push(<div key="wrapper" className="ibox-action-holder">
        <div className="ibox-actions">
        Limit: <ReactSelect value={limitValue} options={limitOptions} onChange={this.setLimit} className="single-select select-limit"/> 
          <ReactSelect value={this.state.sort} options={this.options} onChange={this.setSort} className="single-select"/>
          <ConfirmClick confirmAction={this.exportToCsv} title="Export CSV" confirmClass="" confirmLabel={<span key="download" className="chart-icon download-link" dangerouslySetInnerHTML={{__html: downloadSvg}} />} confirmPrompt="These reports are for analytical purposes only and should not be used to pay artist royalties"></ConfirmClick> 
        </div>
        </div>)
        for (let mode in modes) {
             toolbar.push(<a key={mode}><i alt={mode} className={`fa fa-${modes[mode]} ${this.state.mode == mode ? 'active' : ''}`} onClick={()=>this.setChartMode(mode)}></i></a>);                
        }

        return toolbar;
    }
    
    exportToCsv(){
      if(this.state.mode == 'table') {
        this.props.dispatch(statsActions.getTerritoryStats(this.props.entity, this.props.ids, this.state.sort, config.fullCSVThreshold, this.state.page)).then(()=>{
            const filename = CSV.CSVHeader('details_territories', this.state.sort.field, this.props.filter.global, this.props.parentEntityTitle); 
            return CSV.CSVExport(this.props.stats.territory.table, {filename}, 'territories');            
        })
      }
      else {
          const data = this.props.stats.territoryTimeseries;
          let table=[];
          for(let row of data.datasets) {
              let tableRow = {code: row.id, name: row.label};
              for(let labelIndex in data.labels) {
                  tableRow[data.labels[labelIndex]] = row.data[labelIndex];
              }
              table.push(tableRow);
          }
          const filename = CSV.CSVHeader('territories_timeseries', this.state.sort.field, this.props.filter.global, this.props.parentEntityTitle);
          return CSV.CSVExport(table, {filename});
      }
              
    }

    setSort(sort){
        this.setState({sort}, ()=>{
            this.props.dispatch(statsActions.getTerritoryStats(this.props.entity, this.props.ids, this.state.sort, this.state.limit, this.state.page))
        })
    }

    setLimit(limit){
        this.setState({limit: limit.value, page: 0}, ()=>{
            this.props.dispatch(statsActions.getTerritoryStats(this.props.entity, this.props.ids, this.state.sort, this.state.limit, this.state.page))
            store.set('territoryLimit', limit.value);
        })
    }
    
    setPage(page){
        this.setState({page}, ()=>{
            this.props.dispatch(statsActions.getTerritoryStats(this.props.entity, this.props.ids, this.state.sort, this.state.limit, this.state.page))
        })
    }
    

    componentDidMount() {
        const { chartType } = this.props;
        this.props.dispatch(statsActions.getTerritoryStats(this.props.entity, this.props.ids, this.state.sort, this.state.limit, this.state.page))

        if (this.props.filter.global) {
          if (this.props.filter.global !== undefined) {
            this.setState({
              logoDataFiltered: chartType
                ? dspLogos[chartType].data.filter((l) =>
                    this.props.filter.global.vendors.includes(l)
                  )
                : [],
            });
          }
        }
    }
    
    getTimeseries() {
        const ids = this.props.stats.territory ? map(this.props.stats.territory.table, 'code') : [];
        this.props.dispatch(statsActions.getTerritoryTimeseries(this.props.entity, this.props.ids, ids, this.state.sort, this.state.limit, this.state.page))
    }
     
    componentWillReceiveProps(nextProps){
        const globalFilter = nextProps.filter.global;
        const { chartType } = this.props;
        if(nextProps.filter && globalFilter){
            if(this.props.filter.global!==undefined && !isEqual(globalFilter, this.props.filter.global)) {
                this.props.dispatch(statsActions.getTerritoryStats(this.props.entity, this.props.ids, this.state.sort, this.state.limit, this.state.page))
                this.setState((prevState) => {
                  return {
                    ...prevState,
                    logoDataFiltered: chartType
                      ? dspLogos[chartType].data.filter((l) =>
                          globalFilter.vendors.includes(l)
                        )
                      : [],
                  };
                });
            }                
        }
        if(nextProps.stats.forceLoadAllTerritoriesData && !this.props.stats.forceLoadAllTerritoriesData) {
            console.log('loading export data for territories');
            this.props.dispatch(statsActions.getTerritoryStats(this.props.entity, this.props.ids, this.state.sort, config.fullCSVThreshold, 0));
        }
        
    }

    renderVendorLogos() {
        const { chartType } = this.props;
        const logoData = chartType ? this.state.logoDataFiltered : [];

        return <div className="title-vendors-list">
            {chartType && logoData && logoData.map((l) => (
                <VendorLogo key={l} name={l} />
            ))}
        </div>
    }

    render (){
        const { stats, expanded = false } = this.props;
        const { limit } = this.state;
        const title = <React.Fragment>Territories <br/><div className="small-description">This section is independent from the Main Filter Limit in order to give you an accurate territory market share no matter how many results you’re looking at. By default you can see the top 10 but you can toggle this in the Limit drop down menu on the right. You can still select a specific Vendor in the Main Filter.</div></React.Fragment>;
        const totals = stats.territoryTotals;
        const totalRows = totals ? totals.total_rows : limit;
        let columns = territorySettings.settingsForTable(expanded?'expanded':'details');
        for(let column of columns) {
            column.Footer = (props) => {
                const {id} = column;
                const [key, metric] = id.split('_');
                let value = totals[key];
                if(metric == 'income')
                    value = <React.Fragment>{splitByThousands(totals[`${key}_income`], '$')}<br/>{splitByThousands(totals[`${key}_quantity`])}</React.Fragment>
                else if(key == 'name')
                    value = 'Total';
                return <span>{value}</span>
            };
        }
        
        let tableData = stats.territory ? stats.territory.table : null;
        if(tableData && tableData.length)
            tableData = tableData.slice(0, limit);

        return <div className="table-with-chart-holder table-header-with-description">
            <Box title={title} toolbar={this.renderToolbar()} vendorLogoList={this.renderVendorLogos()} spinnerEnabled={this.props.stats.territoryLoading || this.props.stats.territoryTimeseriesLoading} className="ibox-container box-table">
                {stats.territory && <div>
                    {this.state.mode=='map' && <TerritoryStatsMap expanded={expanded} data={this.props.stats.territory} />}
                    {(this.state.mode=='line' && !this.props.stats.territoryTimeseriesLoading) &&  <div className="territory-chart"><LineChart data={this.props.stats.territoryTimeseries} label="streams" /></div> }
                    {this.state.mode=='table' && <div className="territory-table territory-table--single custom-scroll">
                        <CellHeaderTable
                            className="territory-table-holder playlist-table"
                            data={tableData}
                            columns={columns}
                            defaultPageSize={Math.min((stats.territory.table?stats.territory.table.length:limit), limit)}
                        minRows={1}
                            sortable={false}
                            manual
                            page={this.state.page}
                            pages={Math.ceil(totalRows / limit)}
                            onPageChange={this.setPage}
                            showPagination={totalRows > limit}
                            showPageSizeOptions={false}
                        />
                    </div>}
                </div>}
            </Box>
            {this.state.mode=='table' && <div className="table-chart-holder">
                <div className="table-chart-holder-inner"> 
                    <div className="chart-title">Territories</div>
                    {this.props.stats.territory && <DoughnutChart data={this.props.stats.territory.chart} chartID="top_territories_pie_chart" />}
                </div>
            </div>}
        </div>
    }
}

function mapStateToProps(state) {
    return {
        stats: state.stats,
        filter: state.filter
    } 
}

export default connect(mapStateToProps)(TerritoryStats);