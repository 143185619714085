import { map, find, uniqBy } from 'lodash'
import moment from 'moment';
import ImageURL from '../../helpers/ImageURL';
import icons from '../../helpers/Icons';
import { formatChartLabel, formatChartLabelItem, sortDateRange } from '../../helpers/DateFormatter';
import { colors } from '../../components/widgets/charts/ChartDefaults';
import {dateRange, formattedDateRange, dateRangeFlat} from '../../helpers/DateRange';
import {percentageValue} from '../../helpers/NumberFormatter';
import AddTotal from '../../components/common/Datagrid/AddTotal';
import VendorHelper from '../../components/common/Datagrid/VendorHelper';
import FixNumericFields from '../helpers/FixNumericFields';

export const imprintsFormatter = {
    formatForPercentageChart,
    formatForTable,
    formatImprints,
    formatForTableOld,
    formatTop,
    formatAutocomplete,
    formatAutocompleteItem,
    formatDetails,
    formatCard,
    formatCatalogCard,
    formatStreamsStats,
    formatMetadata,
    formatTimeseries
};


function formatForPercentageChart(data, column){
    let dataset = [], 
        labels = [];
    
    for(let entry of data) {
        dataset.push(entry[column]);
        labels.push(entry.name);
    }
    return {labels, datasets:[{data: dataset, label: 'Streams'}]};
}

function formatForTable(data){
    if(!data)
        return [];

    for(let entry of data.data) {
        entry.id = entry.imprint_id;
        entry.name = entry.imprint_name;
        entry.logo = ImageURL(entry.imprint_image, entry.id, 'imprints');
        entry.engaged = Math.round((entry.active / entry.curr_units) * 100);
    }
    data.data = AddTotal(data.data, 'imprint');
    return data;
}

function formatImprints(data, addTotal){
    if(!data)
        return [];

    for(let entry of data) {
        entry.id = parseInt(entry.imprint_id);
        entry.name = entry.imprint;
        entry.logo = ImageURL(entry.imprint_image, entry.id, 'imprints');
    }
    if(addTotal)
    	data = AddTotal(data);
    return data;
}


function formatForTableOld(data){
    if(!data)
        return [];
    for(let entry of data.data) {
        entry.logo = entry.logo_file_name;
    }
    return data;
}

function formatTop(data){
    if(!data)
        return [];    
    data = map(data, (entry)=>{
        entry.id = entry.imprint_id;
        entry.code = null;
        return entry;
    });
    return FixNumericFields(data);
}

function _formatAutocompleteItem(hit){
    if(hit.entity)
        return hit;

    const entity = 'imprints',
        name = hit.name? hit.name : hit._source.name,
        id = hit.id ? hit.id : hit._id;
    
    return {
        id,
        entity,
        name: `${name} (imprint)`,
        logo: icons.entities[entity]
    }
}

function formatAutocompleteItem(hit){
    const entity = 'imprints';
    
    return {
        id: hit.id,
        entity,
        name: `${hit.name} (label)`,
        name_raw: hit.name,
        logo: icons.entities[entity],
        code: hit.id
    }
}


function formatAutocomplete(data) {
    let {imprints}= data;
    return imprints.map(formatAutocompleteItem);
}

function formatDetails(data){
    if(!data)
        return [];

    let labels = [],
        dataset = [];
    
    data = data.sort(sortDateRange);
    
    for(let item of data) {
        labels.push(formatChartLabel(item));
        dataset.push(item.units);
    }
    return {labels, datasets: [{data: Object.values(dataset), label: 'plays'}]};
}

function formatCard(data) {
	let result = {}
	if(data.result && data.result.length) {
		result = data.result[0];
		result.id = parseInt(result.imprint_id);
		result.logo = ImageURL(result.image, result.id, 'imprints');
	}
	return result;
}

function formatCatalogCard(data) {
    data = data.data;
    return {
        id: data.id,
        name: `${data.name} (imprint)`,
        name_raw: `${data.name}`,
        logo: data.logo_file_name
    }
}

function formatStreamsStats(streams, metadata) {
    let labels = [],
        datasets = [];
    if(streams) {
        const {dates, period} = dateRangeFlat(streams);
        datasets = metadata.map((imprint, index)=>{
            let data = [];
            for(let date of dates) {
                let stream = find(streams, {stream_date: date, imprint_id: imprint.id});
                data.push(stream ? stream.curr_units : null);
            }    

            return {
                id: imprint.id,
                label: imprint.name,
                data,
                fill: false,
                borderColor: colors[index%colors.length],
                backgroundColor: colors[index%colors.length],
                pointBorderColor: colors[index%colors.length],
                pointBackgroundColor: colors[index%colors.length],                

            }
        });    
        
        labels =  map(dates, (date)=>formatChartLabelItem(date, period));
    }
    return { labels, datasets };
}

function formatMetadata(entities, metadata, total) {
    const { total_income } = total;
    for(let entity of entities) {
        const metaEntity = find(metadata, {id: entity.id});
        if(metaEntity) {
            entity.name = entity.imprint_name = metaEntity.name;
            entity.image = ImageURL(metaEntity.logo_file_name, entity.id, 'imprints');
        }
        else if(entity.id === null) {
            entity.name = 'Adjustments';
            entity.image = null;        
        }
        entity.share = Math.round(entity.total_income/total_income*10000)/100;
    }
    return entities;

}

function formatTimeseries(streams, metadata) {
    let labels = [],
	    datasets = [];
	if(streams) {
		for(let stream of streams) {
			stream.imprint_id = parseInt(stream.imprint_id); 
			console.log(stream);
		}
	    const {dates, period} = dateRangeFlat(streams);
	    datasets = metadata.map((imprint, index)=>{
	        let data = [];
	        for(let date of dates) {
	            let stream = find(streams, {report_date: date, imprint_id: imprint.id});
	            data.push(stream ? Number(stream.total_income).toFixed(2) : null);
	        }    
	
	        return {
	            id: imprint.id,
	            label: imprint.name,
	            data,
	            fill: false,
	            borderColor: colors[index%colors.length],
	            backgroundColor: colors[index%colors.length],
	            pointBorderColor: colors[index%colors.length],
	            pointBackgroundColor: colors[index%colors.length],                
	
	        }
	    });    
	    
	    labels =  map(dates, (date)=>formatChartLabelItem(date, 'Month'));
	}
	return { labels, datasets };

}