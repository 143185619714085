import React from 'react';
import { Line } from 'react-chartjs-2';
import { map, concat, merge } from 'lodash';
import { colors, line } from '../ChartDefaults';
import moment from 'moment';

class LineChart extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            //data: this.buildChartData(props.data),
            options: merge({}, line, props.options)
        };
        this.getData = this.getData.bind(this);
    }
    
    componentWillMount() {
        //Chart.elements.Rectangle.prototype.draw = RectangularDraw;     
    }

    componentWillReceiveProps(props){
        /*
        this.setState({
            data: this.buildChartData(props.data)
        })
        */
    }
    
    getData(canvas) {
        return this.props.data;
        let {labels, dataset} = this.props.data;
        if(!dataset)
            return false;
        
        const ctx = canvas.getContext("2d");
        
        const gradientColors = colors;
        
        dataset = {
            label: '',
            backgroundColor: gradientColors[0],
            data: Object.values(dataset)
        };
        
        labels = labels.map(label=>moment(label).format('DD MMM'));
        return {
            labels,
            datasets: [dataset]
        }
        
    }
    
    render() {
        const { data, options, ...rest } = this.props;
        return (this.props.data ? <Line data={this.getData} options={this.state.options} {...rest}></Line> : null);
    }
}

export default LineChart